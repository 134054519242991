<template>
  <div class="h-full relative z-50">
    <div class="px-5 py-6">
      <NuxtLink
        to="/home"
        class="flex gap-2 items-center mb-2 text-white"
      >
        <img
          src="/img/portoll-sidebar-logo.svg"
          alt="Portoll Logo"
          class="w-8"
          draggable="false"
        >
      </NuxtLink>
    </div>

    <div class="grid grid-cols-1 px-3">
      <NuxtLink
        to="/home"
        class="border border-transparent flex gap-3 items-center font-medium p-3 rounded text-[#AEBCCE] hover:text-white hover:text-white"
        active-class="bg-[#0F172A] border-[#39455A] hover:text-[#AEBCCE]"
      >
        <img
          src="/img/icons/portoll-icon.svg"
          class="h-5 w-5"
        >

        {{ auth.isOwner ? 'My Portoll' : 'Home' }}
      </NuxtLink>

      <NuxtLink
        v-if="auth.isOwner"
        to="/files"
        class="border border-transparent flex gap-3 items-center font-medium p-3 rounded text-[#AEBCCE] hover:text-white hover:text-white"
        active-class="bg-[#0F172A] border-[#39455A]"
      >
        <img
          src="/img/icons/files-icon.svg"
          class="h-5 w-5"
        >

        Files / Media
      </NuxtLink>

      <NuxtLink
        v-if="auth.isOwner"
        to="/subscribers"
        class="border border-transparent flex gap-3 items-center font-medium p-3 rounded text-[#AEBCCE] hover:text-white hover:text-white"
        active-class="bg-[#0F172A] border-[#39455A]"
      >
        <img
          src="/img/icons/subscribers-icon.svg"
          class="h-5 w-5"
        >

        Subscribers
      </NuxtLink>

      <NuxtLink
        to="/messages"
        class="flex gap-border border-transparent 3 items-center justify-between p-3 rounded text-[#AEBCCE] hover:text-white hover:text-white"
        active-class="bg-[#0F172A] border-[#39455A]"
      >
        <div class="flex gap-3 items-center">
          <img
            src="/img/icons/messages-icon.svg"
            class="h-5 w-5"
          >

          Messages
        </div>

        <span class="self-center font-inter font-medium text-xs text-white bg-indigo-500 px-2 py-0.5 rounded text-right">3</span>
      </NuxtLink>

      <NuxtLink
        to="/notifications"
        class="border border-transparent flex gap-3 items-center font-medium p-3 rounded text-[#AEBCCE] hover:text-white hover:text-white"
        active-class="bg-[#0F172A] border-[#39455A]"
      >
        <img
          src="/img/icons/notifications-icon.svg"
          class="h-5 w-5"
        >

        Notifications
      </NuxtLink>

      <NuxtLink
        v-if="auth.isOwner"
        to="/finances"
        class="border border-transparent flex gap-3 items-center font-medium p-3 rounded text-[#AEBCCE] hover:text-white hover:text-white"
        active-class="bg-[#0F172A] border-[#39455A]"
      >
        <img
          src="/img/icons/finances-icon.svg"
          class="h-5 w-5"
        >

        Finances
      </NuxtLink>

      <NuxtLink
        v-if="auth.isOwner"
        to="/portoll/settings"
        class="border border-transparent flex gap-3 items-center font-medium p-3 rounded text-[#AEBCCE] hover:text-white hover:text-white"
        active-class="bg-[#0F172A] border-[#39455A]"
      >
        <img
          src="/img/icons/portoll-settings-icon.svg"
          class="h-5 w-5"
        >

        Portoll Settings
      </NuxtLink>

      <slot />
    </div>

    <div class="absolute bottom-0 bg-[#0F172A] flex items-center justify-between p-4 text-[#AEBCCE] w-full">
      <Menu
        class="relative"
        as="div"
      >
        <MenuButton class="flex items-center gap-2 text-[#E2E8F0]">
          <Icon
            name="material-symbols:account-circle"
            class="h-8 w-8 text-slate-500"
          />

          {{ auth.user.first_name }} {{ auth.user.last_name }}
        </MenuButton>

        <Transition
          enter-active-class="transition duration-200 ease-out"
          enter-from-class="transform scale-95 opacity-0"
          enter-to-class="transform scale-100 opacity-100"
          leave-active-class="transition duration-150 ease-out"
          leave-from-class="transform scale-100 opacity-100"
          leave-to-class="transform scale-95 opacity-0"
        >
          <MenuItems class="absolute bottom-16 bg-white py-4 rounded shadow-lg w-64">
            <MenuItem
              v-slot="{ active }"
              class="text-slate-900 hover:bg-slate-200/50"
            >
              <NuxtLink
                to="/account"
                class="block px-4 py-2"
                :class="{ 'bg-slate-100': active }"
              >
                My Account
              </NuxtLink>
            </MenuItem>

            <MenuItem
              v-slot="{ active }"
              class="text-slate-900 hover:bg-slate-200/50"
            >
              <NuxtLink
                to="/account/settings"
                class="block px-4 py-2"
                :class="{ 'bg-slate-100': active }"
              >
                Account Settings
              </NuxtLink>
            </MenuItem>

            <MenuItem
              v-slot="{ active }"
              class="text-slate-900 hover:bg-slate-200/50"
            >
              <button
                class="block px-4 py-2 text-left w-full"
                :class="{ 'bg-slate-100': active }"
                @click="auth.logout()"
              >
                Logout
              </button>
            </MenuItem>
          </MenuItems>
        </Transition>
      </Menu>

      <button>
        <Icon
          name="material-symbols:menu"
          class="text-3xl text-slate-500"
        />
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { useAuthStore } from '~/stores/useAuthStore';
  import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue';

  const auth = useAuthStore();

  // const {$intro} = useNuxtApp();

  // if ($intro) $intro().setOptions({
  //   steps: [
  //   {
  //       element: '#portoll-sidebar',
  //       intro: 'Hello world!',
  //       position: 'bottom',
  //     },
  //     {
  //       element: '#portoll-header',
  //       intro: 'Header!',
  //     },
  //     {
  //       element: '#portoll-page-content',
  //       intro: 'Test!',
  //     },
  //   ]
  // }).start();
</script>
